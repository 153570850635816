// Offcanvas Funcionality

document.querySelector('.headerbar__content-navtoggler').addEventListener('click', () => {
  document.body.classList.toggle('activenav');
})

// Navigation Functionality
for (let parentMenuItem of document.querySelectorAll('.headerbar__content-mainnavcontainer-nav > ul li.parent > a, .headerbar__content-mainnavcontainer-nav > ul li.parent > span ')) {
  parentMenuItem.addEventListener('click', (e) => {
    console.log(parentMenuItem);
    e.preventDefault();
    // If first Layer Item
    if(parentMenuItem.parentElement.parentElement.classList.contains('nav')) {
      // Check if Clicked and remove background and clicked state
      if(parentMenuItem.parentElement.classList.contains('clicked')) {
        document.body.classList.remove('activedropdownnav');
        parentMenuItem.parentElement.classList.remove('clicked');
        return;
      }

      // Remove clicked state for all first Level Items
      for (let parentMenuItem of document.querySelectorAll('.headerbar__content-mainnavcontainer-nav > ul > li.parent')) {
        parentMenuItem.classList.remove('clicked');
      }

      // Add clicked state if not already set
      document.body.classList.add('activedropdownnav');
      parentMenuItem.parentElement.classList.add('clicked');

      return
    }
    // General
    parentMenuItem.parentElement.classList.toggle('clicked');
  })
}

document.querySelector('.menubackground').addEventListener('click', () => {
  if (window.innerWidth >= 1200) {
    for (let parentMenuItem of document.querySelectorAll('.headerbar__content-mainnavcontainer-nav > ul > li.parent > a, .headerbar__content-mainnavcontainer-nav > ul > li.parent > span ')) {
      parentMenuItem.parentElement.classList.remove('clicked');
    }
    document.body.classList.remove('activedropdownnav');
  }
})